import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Preferences } from '@capacitor/preferences';
import { LanguageType, ThemeType, PreferencesKey } from '../../enums';
import { QueryBuilder } from '../../interfaces/strapi-query-builder/query_builder.interface';

const BASE_URL = environment.apiUrl;
const BEARER_TOKEN = environment.authorizationToken;
const HTTP_OPTIONS = {
  headers: new HttpHeaders({ Authorization: BEARER_TOKEN }),
};

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private language = LanguageType.NL;
  private theme = ThemeType.STRESS;
  public currentTheme = '';

  constructor(protected http: HttpClient) {}

  public async preferences(): Promise<void> {
    const langPref = await Preferences.get({ key: PreferencesKey.LANGUAGE });
    const themPref = await Preferences.get({ key: PreferencesKey.THEME });

    if (langPref) {
      this.language = langPref.value as LanguageType;
    }

    if (themPref) {
      this.theme = themPref.value as ThemeType;
    }
  }

  public queryBuilder({ endpoint, populate, filter }: QueryBuilder): Observable<any> {
    // ! WE CAN'T USE FIELDS, SORT, AND LIMIT IF POPULATE CONTAINS MEDIA INSIDE RELATIONFIELD
    // ! NEED TO FIGURE IT OUT HOW TO DO THAT IN STRAPI QUERY BUILDER

    let optionIndex = 0;
    let fieldsQueries: string[] = [];
    let relationFieldsQueries: string[] = [];

    let finalFieldsQueries: string = '';
    let finalRelationFieldsQueries: string = '';

    if (populate) {
      if (populate.fields) {
        for (let i = 0; i < populate.fields.length; i++) {
          fieldsQueries.push(`fields[${optionIndex}]=${populate.fields[i]}`);
          optionIndex++;
        }
      }

      if (populate.mediaFields) {
        for (let i = 0; i < populate.mediaFields.length; i++) {
          fieldsQueries.push(
            `populate[${optionIndex}]=${populate.mediaFields[i]}`
          );
          optionIndex++;
        }
      }

      if (populate.sort) {
        fieldsQueries.push(`sort[${optionIndex}]=${populate.sort}`);
        optionIndex++;
      }

      if (populate.pagination) {
        let limit = populate.pagination?.limit || 0;

        if (limit > 0) {
          limit+=1;
          fieldsQueries.push(`pagination[limit]=${limit.toString()}`);
        }
      }

      finalFieldsQueries =
        fieldsQueries.length > 0 ? `&${fieldsQueries.join('&')}` : '';
      // check relationFields
      if (populate.relationField) {
        for (let i = 0; i < populate.relationField.length; i++) {
          let relationField = populate.relationField[i].fields || [];
          let relationMediaFields = populate.relationField[i].mediaFields || [];

          if (relationField.length === 0 && relationMediaFields.length === 0) {
            relationFieldsQueries.push(
              `populate[${populate.relationField[i].attribute}][fields][${optionIndex}]=*`
            );
            optionIndex++;
          }

          if (relationField.length > 0) {
            for (let j = 0; j < relationField.length; j++) {
              relationFieldsQueries.push(
                `populate[${populate.relationField[i].attribute}][fields][${optionIndex}]=${relationField[j]}`
              );
              optionIndex++;
            }
          }

          if (populate.relationField[i].sort) {
            relationFieldsQueries.push(
              `populate[${populate.relationField[i].attribute}][sort]=${populate.relationField[i].sort}`
            );
          }

          if (populate.relationField[i].pagination) {
            let limit = populate.relationField[i].pagination?.limit || 0;
            if (limit > 0) {
              limit+=1;
              relationFieldsQueries.push(
                `populate[${
                  populate.relationField[i].attribute
                }][limit]=${limit.toString()}`
              );
            }
          }

          if (relationMediaFields.length > 0) {
            for (let j = 0; j < relationMediaFields.length; j++) {
              relationFieldsQueries.push(
                `populate[${optionIndex}]=${populate.relationField[i].attribute}.${relationMediaFields[j]}`
              );
              optionIndex++;
            }
          }
        }
        finalRelationFieldsQueries =
          relationFieldsQueries.length > 0
            ? `&${relationFieldsQueries.join('&')}`
            : '';
      }
    }

    let url = `${BASE_URL}${endpoint}?locale=${this.language}${finalFieldsQueries}${finalRelationFieldsQueries}`;

    return this.http.get(url, HTTP_OPTIONS);
  }
}
