<div class="video-container">
  <div *ngIf="media">
    <ng-container #youtube_player *ngIf="type === 'youtube'">
      <iframe
        title="yt-player"
        class="video-player"
        #videoPlayer
        [src]="videoUrl"
        frameborder="0"
        allow="autoplay;"
      >
      </iframe>
    </ng-container>

    <ng-container #video_player *ngIf="type === 'uploaded'">
      <video
        class="video-player"
        controls
        #uploadedMediaPlayer
        [poster]="thumbnail"
        playsinline
        [src]="videoUrl"
      ></video>
    </ng-container>
  </div>
</div>
