<mat-sidenav-container
  [class]="changeSideNavClass()"
  (backdropClick)="toggleSidenav()"
  [ngStyle]="{
    'padding-top': !isLockscreen() ? 'env(safe-area-inset-top)' : '0rem',
    'background-color': !isLockscreen() ? 'white' : ''
  }"
  [class]="isShowing ? 'sidenav-toggled' : changeSideNavClass()"
>
  <!-- Toolbar -->
  <mat-toolbar
    [class]="changeTopBarClass()"
    [ngStyle]="{
      display: !isLockscreen() ? 'flex' : 'none'
    }"
  >
    <div class="left-nav">
      <fa-icon
        *ngIf="!showbackIcon"
        [icon]="switchMenuIcon()"
        (click)="toggleSidenav()"
        size="lg"
        class="menu-icon"
      ></fa-icon>
      <fa-icon
        *ngIf="showbackIcon"
        [icon]="faArrowLeft"
        (click)="goBack()"
        size="lg"
        class="menu-icon"
      ></fa-icon>
      <div class="logo" *ngIf="changeSideNavClass() !== 'full-screen'">
        <img
        [src]="themeConf.logoSrc"
        [alt]="themeConf.logoAlt"
        [ngClass]="{'logo-img':!isWhitelabelVariant, 'logo-img-arbolink': isWhitelabelVariant}"
        />
        <div class="vr" *ngIf="!isWhitelabelVariant"></div>
        <div class="title" *ngIf="!isWhitelabelVariant">
          Balance
        </div>
      </div>
    </div>

    <div class="title-video" *ngIf="changeSideNavClass() === 'full-screen'">
      <p>{{ title }}</p>
    </div>
    <div class="right-nav"></div>
    <!-- <div class="vr"></div>
    <div class="title">
      <h6>Balance</h6>
    </div> -->
    <!-- <button class="emdr-button" disabled></button> -->
  </mat-toolbar>
  <!-- sidenav -->
  <mat-sidenav
    [opened]="isShowing"
    mode="over"
    (keydown.escape)="toggleSidenav()"
    class="mat-sidenav"
  >
    <div class="wrapper">
      <div class="header">
        <p>{{themeConf.appName}}</p>
        <div class="close-button">
          <fa-icon
            *ngIf="!showbackIcon"
            [icon]="switchMenuIcon()"
            (click)="toggleSidenav()"
            size="lg"
            class="menu-icon"
          ></fa-icon>
        </div>
      </div>
      <div class="content">
        <div class="links">
          <a href="/about">
            <div class="link">
              <fa-icon [icon]="faContact" size="lg"></fa-icon>
              <p>{{ about }}</p>
            </div>
          </a>

          <div class="link privacy" (click)="showIframe()">
            <fa-icon [icon]="faPrivacy" size="lg"></fa-icon>
            <p>{{ privacy }}</p>
          </div>
        </div>
        <!-- Settings -->
        <div class="settings">
          <p class="settingsTitle">{{ translate("settings") }}</p>
          <!-- <div class="buttonToggle">
            <p>{{ translate("enableAlgorithm") }}</p>
            <mat-slide-toggle
              (toggleChange)="toggleAlgorithm()"
              [(ngModel)]="isChecked"
            ></mat-slide-toggle>
          </div> -->
          <div class="buttonToggle">
            <p>{{ translate("enable.notification") }}</p>
            <mat-slide-toggle
              (toggleChange)="toggleNotification()"
              [(ngModel)]="notificationCheck"
            ></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="version">
        <div class="button-group">
          <mat-button-toggle-group
            name="fontStyle"
            (change)="onChange($event)"
            value="{{ language }}"
            color="accent"
          >
            <mat-button-toggle class="eng" value="en">ENG</mat-button-toggle>
            <mat-button-toggle class="nl" value="nl">NL</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="version-text">
          <span>{{ translate("version") }} {{ appVersion }}</span>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <!-- content page -->
  <router-outlet
    (activate)="isOverviewComponent()"
    [class]="changeMarginComponent()"
  ></router-outlet>
  <!-- navbar -->
  <app-navbar
    class="bottom-bar"
    [ngStyle]="{ display: !hasRoute() ? 'flex' : 'none' }"
  ></app-navbar>
</mat-sidenav-container>

<!-- popup iframe with the privacy page of moovd.nl -->
<div class="popup">
  <div class="container" [class.show]="iframeVisible">
    <div class="iframe-overlay" [class.show]="iframeVisible">
      <div class="iframe-popup">
        <div class="popup-header">
          <span class="close-button" (click)="hideIframe()">&times;</span>
        </div>
        <iframe
        *ngIf="isWhitelabelVariant"
          title="privacy-policy"
          src="https://arbolink.nl/werkgever"
          frameborder="0"
        ></iframe>
        <iframe
        *ngIf="!isWhitelabelVariant"
          title="privacy-policy"
          src="https://moovd.nl/privacy-policy/"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</div>
