import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalNotifications } from '@capacitor/local-notifications';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notificationEnabled = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
    this.setupNotificationListener();
  }

  public async addNotification(timespan: number = 28) {
    const requestPermission = await LocalNotifications.requestPermissions();

    if (requestPermission.display === 'granted') {
      const pendingNotifications = await LocalNotifications.getPending();
      const futureDates = this.scheduleNotifications(timespan);

      for (let i = 0; i < futureDates.length; i++) {
        const pendingNotificationDate = futureDates[i];

        const notificationExists = pendingNotifications.notifications.some(
          (notification) => {
            if (notification.schedule && notification.schedule.at) {
              const scheduledTime = new Date(notification.schedule.at);
              return (
                scheduledTime.getTime() === pendingNotificationDate.getTime()
              );
            }
            return false;
          }
        );

        let notificationTitle;
        let notificationBody;

        if (futureDates.length == timespan) {
          if (i === 6) {
            notificationTitle = this.translate('notification.title.7days');
            notificationBody = this.translate('notification.body.7days');
          } else if (i === 13) {
            notificationTitle = this.translate('notification.title.14days');
            notificationBody = this.translate('notification.body.14days');
          } else if (i === 27) {
            notificationTitle = this.translate('notification.title.28days');
            notificationBody = this.translate('notification.body.28days');
          }else {
            notificationTitle = this.translate('notification.title');
            notificationBody = this.translate('notification.body');
          }
        } else {
          if (i === 5) {
            notificationTitle = this.translate('notification.title.7days');
            notificationBody = this.translate('notification.body.7days');
          } else if (i === 12) {
            notificationTitle = this.translate('notification.title.14days');
            notificationBody = this.translate('notification.body.14days');
          } else if (i === 26) {
            notificationTitle = this.translate('notification.title.28days');
            notificationBody = this.translate('notification.body.28days');
          } else {
            notificationTitle = this.translate('notification.title');
            notificationBody = this.translate('notification.body');
          }
        }

        if (!notificationExists) {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: notificationTitle,
                body: notificationBody,
                id: i + 1,
                schedule: { at: pendingNotificationDate, every: 'day' },
                actionTypeId: 'OPEN_BALANCE_PAGE',
                extra: {
                  route: '/home',
                },
              },
            ],
          });
        }
      }
    } else {
      console.log('Notification permission denied!');
    }
  }

  public async removeAllNotifications() {
    const requestPermission = await LocalNotifications.requestPermissions();
    if (requestPermission.display === 'granted') {
      const pendingNotifications = await LocalNotifications.getPending();
      const notificationIds = pendingNotifications.notifications.map(
        (notification) => notification.id
      );

      if (notificationIds.length > 0) {
        await LocalNotifications.cancel({
          notifications: notificationIds.map((id) => ({ id })),
        });
      }
    }
  }

  public async getListOfPendingNotifications() {
    const pendingNotifications = await LocalNotifications.getPending();
    console.log(
      'List of pending notifications:',
      pendingNotifications.notifications
    );
  }

  private translate(key: string) {
    return this.translateService.instant(key);
  }

  private async setupNotificationListener() {
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (payload) => {
        const route = payload.notification.extra.route;
        this.router.navigate([route]);
      }
    );
  }

  // every 9a.m
  private scheduleNotifications(timespan: number = 14): Date[] {
    const now = new Date();
    const dates = [];
    const currentDate = new Date();

    for (let i = 0; i < timespan; i++) {
      const futureDate = new Date(currentDate);

      if (i === 0) {
        currentDate.setHours(9, 0, 0, 0);
        if (currentDate >= now) {
          futureDate.setDate(currentDate.getDate() + i);
          futureDate.setHours(9, 0, 0, 0);
          dates.push(futureDate);
        }
      } else {
        futureDate.setDate(currentDate.getDate() + i);
        futureDate.setHours(9, 0, 0, 0);
        dates.push(futureDate);
      }
    }

    return dates;
  }
}
