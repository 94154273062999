export enum FeelingType {
    TERRIBLE = "terrible",
    SAD = "sad",
    OKAY = "okay",
    FINE = "fine",
    GREAT = "great",
}

export enum FactorType {
    THOUGHTS = "thoughts",
    SLEEP = "sleep",
    STRESS = "stress",
    ENERGY = "energy",
    PHYSICAL = "physical",
    WORK = "work",
    PRIVATE = "private"
}