import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class PlayerComponent implements OnInit {
  @Input() type= '';
  @Input() media= '';
  @Input() thumbnail= '';
  @ViewChild('uploadedMediaPlayer') videoPlayer: ElementRef | undefined;
  @ViewChild('playButton') playButton: ElementRef | undefined;

  public videoUrl?: SafeResourceUrl;
  public isPlaying = false;

  constructor(private sanitizer: DomSanitizer, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.loadVideo();
  }

  /**
   * Convert the video url with sanitizer and set it to the videoUrl.
   */
  private loadVideo(): void {
    const url: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.media + '?autoplay=0'
    );
    this.videoUrl = url;
  }

  /**
   * Trigger a play || pause on the video
   */
  public playVideo() {
    if (this.isPlaying) {
      this.videoPlayer?.nativeElement.pause();
      this.renderer.removeClass(this.playButton?.nativeElement, 'hidden');
    } else {
      this.videoPlayer?.nativeElement.play();
      this.renderer.addClass(this.playButton?.nativeElement, 'hidden');
    }
    this.isPlaying = !this.isPlaying;
  }
}
