<mat-toolbar class="toolbar-nav white">
  <button
    mat-button
    routerLink="/home"
    routerLinkActive="active"
    class="spacer"
  >
    <div class="icon-box">
      <fa-icon [icon]="faHome"></fa-icon>
    </div>
    <span class="subscript">{{ translate("Home") }}</span>
  </button>
  <button
    mat-button
    routerLink="/courses"
    routerLinkActive="active"
    class="spacer"
  >
    <div class="icon-box">
      <fa-icon [icon]="faBook"></fa-icon>
    </div>
    <span class="subscript">{{ translate("Courses") }}</span>
  </button>
  <button
    mat-button
    routerLink="/tools"
    routerLinkActive="active"
    class="spacer"
    (click)="resetTabIndex()"
  >
    <div class="icon-box">
      <fa-icon [icon]="faCube"></fa-icon>
    </div>
    <span class="subscript">{{ translate("Tools") }}</span>
  </button>
  <button
    mat-button
    routerLink="/balance"
    routerLinkActive="active"
    class="spacer"
  >
    <div class="icon-box">
      <fa-icon [icon]="fabalance"></fa-icon>
    </div>
    <span class="subscript">{{ translate("balance") }}</span>
  </button>
</mat-toolbar>
