import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthCollection } from '../../interfaces/collection-types';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  
  private valideCodes: Array<number> = [];

  constructor(protected override http: HttpClient) {
    super(http);
    this.getAuthPincodes().subscribe((res) => {
      this.valideCodes = this.decryptArray(
        res.data.attributes.code,
        environment.secretKey
      );
    });
  }

  public validateSession(): boolean {
    const sessionExpRaw = sessionStorage.getItem('sessionExp');
    
    if (sessionExpRaw) {
      if (dayjs().isBefore(dayjs(sessionExpRaw))) {
        return true;
      }
    }
    
    return false;
  }

  public newSession(pincode: number): boolean {
    if (this.valideCodes.includes(Number(pincode))) {
      this.UpdateSessionExpiration();
      return true;
    }

    return false;
  }

  public UpdateSessionExpiration(): void {
    const sessionExp = dayjs().add(30, 'minute').format('YYYY-MM-DDTHH:mm:ss');
    sessionStorage.setItem('sessionExp', sessionExp);
  }

  private getAuthPincodes(): Observable<AuthCollection> {
    return this.queryBuilder({
      endpoint: `/authentications/1`,
    });
  }

  private decryptArray(encryptedArray: string, secretKey: string): number[] {
    try {
      if (!encryptedArray || !secretKey) {
        throw new Error('Invalid arguments provided');
      }
      const decrypted = CryptoJS.AES.decrypt(encryptedArray, secretKey);
      const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

      const decryptedArray = JSON.parse(plaintext);
      if (!Array.isArray(decryptedArray)) {
        throw new Error('Decrypted data is not an array');
      }
      return decryptedArray;
    } catch (error) {
      console.error('Error decrypting data:', error);
      throw error;
    }
  }
}
