import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [isAuthenticated],
    loadChildren: () =>
      import('./feature/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'courses',
    canActivate: [isAuthenticated],
    loadChildren: () =>
      import('./feature/course/course.module').then((m) => m.CourseModule),
  },
  {
    path: 'tools',
    canActivate: [isAuthenticated],
    loadChildren: () =>
      import('./feature/tools/tools.module').then((m) => m.ToolsModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./feature/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'lockscreen',
    loadChildren: () =>
      import('./feature/lockscreen/lockscreen.module').then(
        (m) => m.LockscreenModule
      ),
  },
  {
    path: 'balance',
    canActivate: [isAuthenticated],
    loadChildren: () =>
      import('./feature/balance/balance.module').then((m) => m.BalanceModule),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
