import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { BehaviorSubject, Observable, filter, from, map, switchMap } from 'rxjs';
import { MentalState } from '../interfaces/collection-types/mentalState.interface';
import { SettingsStorage, SingleQuestionnaireStorage } from '../interfaces/storage-types';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private STORAGE_KEY_COURSES = 'CourseProgress';
  private STORAGE_KEY_MENTAL_STATE = 'MentalState';
  private STORAGE_KEY_CONTENTS = 'Contents';
  private STORAGE_KEY_ALGORITHM_ENABLE = 'your_algorithm_enable_key';
  private STORAGE_KEY_QUESTIONNAIRE_VISIBILITY = 'questionnaire_visibility'
  private STORAGE_KEY_QUESTIONNAIRE = 'Questionnaire';
  private STORAGE_KEY_SETTINGS = 'Settings';
  private STORAGE_KEY_USER_INFO = 'user-info'

  private storageReady = new BehaviorSubject(false);

  constructor(private storage: Storage) {
    this.init();
  }
 
  
  /**
   * Initializes the storage at startup and signals when ready.
   * The ready state is used to let calls wait before accessing the storage when it is not yet initialized.
   */
  private async init() {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    this.storageReady.next(true);
  }

  /**
   * Sets the progress value to the right key (courseId) in the list and saves it again.
   * @param key The courseId used as the key in the storage array.
   * @param value Progress for the course ID that is saved.
   */
  public async setCourseProgress(key: string, value: number) {
    try {
      let storedData = (await this.storage.get(this.STORAGE_KEY_COURSES)) || {};
      storedData[key] = value;
      await this.storage.set(this.STORAGE_KEY_COURSES, storedData);
    } catch (error) {
      // Handle error appropriately (e.g., log or throw)
    }
  }

  /**
   * Retrieves the list of course progress from the local storage.
   * Returns an observable that emits the stored data or an empty object.
   */
  public getCoursesProgress() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_COURSES)))
    );
  }

  public async setContents(value: any) {
    try {
      let storedData =
        (await this.storage.get(this.STORAGE_KEY_CONTENTS)) || {};
      storedData['contents'] = value;
      await this.storage.set(this.STORAGE_KEY_CONTENTS, storedData);
    } catch (error) {
      console.error(error);
    }
  }

  public getContents() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_CONTENTS))),
      map((storedData: Record<any, any>) => {
        if(storedData){
          const records = Object.values(storedData);
          return records;
        }else{
          return []
        }
      })
    );
  }

  public async clearContents() {
    try {
      console.log('storage contents clear! 🗑️');
      await this.storage.remove(this.STORAGE_KEY_CONTENTS);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Mental state
   */
  public async setMentalState(key: string, value: MentalState) {
    try {
      let storedData =
        (await this.storage.get(this.STORAGE_KEY_MENTAL_STATE)) || {};
      storedData[key] = value;
      await this.storage.set(this.STORAGE_KEY_MENTAL_STATE, storedData);
    } catch (error) {
      // Handle error appropriately (e.g., log or throw)
    }
  }

  /**
   * Get all mental state records.
   * @returns An observable that emits an array of all the mental state records.
   */
  public getAllMentalStateRecords() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_MENTAL_STATE))),
      map((storedData: Record<string, MentalState>) => {
        const records = Object.values(storedData);
        return records.length ? records : [];
      })
    );
  }

  /**
   * Get mental state for a specific date.
   * @param date The date for which to retrieve the mental state.
   * @returns An observable that emits the mental state object for the specified date, or undefined if not found.
   */
  public getMentalStateByDate(date: string) {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_MENTAL_STATE))),
      map((storedData: Record<string, MentalState>) =>
        storedData ? storedData[date] : undefined
      )
    );
  }

  /**
   * Clears the mental state storage by removing all the stored mental state records.
   */
  public async clearMentalStateStorage() {
    try {
      await this.storage.remove(this.STORAGE_KEY_MENTAL_STATE);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Clears the mental state storage by removing all the stored mental state records.
   */
  public async clearCourseStorage() {
    try {
      await this.storage.remove(this.STORAGE_KEY_COURSES);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Save enable algorithm boolean
   */
  public async setAlgorithmEnable(value: boolean) {
    try {
      await this.storage.set(this.STORAGE_KEY_ALGORITHM_ENABLE, value);
    } catch (error) {
      // Handle error appropriately (e.g., log or throw)
    }
  }

  /**
   * Get algorithm boolean
   */
  public getAlgorithmEnable() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) =>
        from(this.storage.get(this.STORAGE_KEY_ALGORITHM_ENABLE))
      ),
      map((storedValue: boolean) =>
        typeof storedValue === 'boolean' ? storedValue : undefined
      )
    );
  }

  public async setQuestionnaireData(pincode: number, data: SingleQuestionnaireStorage){
    try {
      let storedData =
        (await this.storage.get(this.STORAGE_KEY_QUESTIONNAIRE)) || {};
      storedData[pincode] = data;
      console.log('questionnaireData stored!✅', storedData);
      await this.storage.set(this.STORAGE_KEY_QUESTIONNAIRE, storedData);
    } catch (error) {
      console.error(error);
    }
  }

  public getQuestionnaireData() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_QUESTIONNAIRE))),
      map((storedData: Record<any, any>) => {
        if(storedData){
          const records = Object.values(storedData);
          return records;
        }else{
          return []
        }
      })
    );
  }

  public async clearQuestionnaireData() {
    try {
      console.log('questionnaire datas clear! 🗑️');
      await this.storage.remove(this.STORAGE_KEY_QUESTIONNAIRE);
    } catch (error) {
      console.error(error);
    }
  }



  public async setQuestionnaireVisibility(value: any){
    try {
      let storedData =
        (await this.storage.get(this.STORAGE_KEY_QUESTIONNAIRE_VISIBILITY)) || {};
      storedData["questionnaireVisibility"] = value;
      await this.storage.set(this.STORAGE_KEY_QUESTIONNAIRE_VISIBILITY, storedData);
    } catch (error) {
      // Handle error appropriately (e.g., log or throw)
    }

  }

  public getQuestionnnaireVisibilty(){
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_QUESTIONNAIRE_VISIBILITY))),
      map((storedData: Record<any, any>) => {
        if(storedData){
          const records = Object.values(storedData);
          return records;
        }else{
          return []
        }
      })
    );
  }

  public async clearQuestionnaireVisibility() {
    try {
      console.log('questionnaire visibility clear! 🗑️');
      await this.storage.remove(this.STORAGE_KEY_QUESTIONNAIRE_VISIBILITY);
    } catch (error) {
      console.error(error);
    }
  }

  

  public async setSettings(settingsData: SettingsStorage) {
    try {
      let storedData =
        (await this.storage.get(this.STORAGE_KEY_SETTINGS)) || {};
      storedData['settings'] = settingsData;
      console.log('settingsData stored!✅', storedData);
      await this.storage.set(this.STORAGE_KEY_SETTINGS, storedData);
    } catch (error) {
      console.error(error);
    }
  }

  public getSettings() {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_SETTINGS))),
      map((storedData: Record<any, any>) => {
        if(storedData){
          const records = Object.values(storedData);
          return records;
        }else{
          return []
        }
      })
    );
  }

  public async setUserInfo(key: string, value: any) {
    try {
      let storedData =
        (await this.storage.get(this.STORAGE_KEY_USER_INFO)) || {};
      storedData[key] = value;
      await this.storage.set(this.STORAGE_KEY_USER_INFO, storedData);
    } catch (error) {
      console.error(error)
    }
  }

  public getUserInfo(): Observable<any> {
    return this.storageReady.pipe(
      filter((ready) => ready),
      switchMap((_) => from(this.storage.get(this.STORAGE_KEY_USER_INFO))),
      map((storedData: Record<string, string>) => {
        if(storedData){
          const records = storedData;
          return records;
        }else{
          return []
        }
      }
      )
    );
  }

  public async clearUserInfo() {
    try {
      await this.storage.remove(this.STORAGE_KEY_USER_INFO);
    } catch (error) {
      console.error(error);
    }
  }
}
