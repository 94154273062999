import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StorageService } from 'src/app/core/services/storage.service';
import { Single_challenge } from 'src/app/core/interfaces/collection-types/challenge.interface';
import { Single_course } from 'src/app/core/interfaces/collection-types/courses.interface';
import { FinishCourseDialogComponent } from 'src/app/feature/course/pages/finish-course-dialog/finish-course-dialog.component';
import { ProgressbarComponent } from '../progressbar/progressbar.component';
import { PlayerComponent } from '../player/player.component';
import { MarkdownPipe } from '../../pipes/markdown.pipe';
import { CommonModule } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { SanitizePipe } from '../../pipes/sanitize.pipe';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { ActivityType } from 'src/app/core/enums';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ProgressbarComponent,
    PlayerComponent,
    MarkdownPipe,
    MatBottomSheetModule,
    SanitizePipe,
  ],
})
export class PageComponent implements OnInit {
  // Data
  @Input() id = 0;
  @Input() content?: Single_course | Single_challenge;
  @Input() progress = 20;

  // variables
  public length = 0;
  public pageCounter = 0;
  public media = '';
  public typeMedia = '';
  public thumbnail = '../../../assets/Images/thumbnail-image2.png';

  constructor(
    private storage: StorageService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private dataService: DataService,
    private activityService: ActivityService
  ) {}

  public translate(key: string) {
    return this.translateService.instant(key);
  }

  ngOnInit(): void {
    /**
     * Maybe add a loading state to this
     */
    if (this.content) {
      console.log(this.content);
      // Setting the length
      this.length = this.content.data.attributes.pages.data.length;
      console.log(this.length);
      // Setting the pageCounter
      this.pageCounter = Math.floor(
        (this.content.data.attributes.pages.data.length - 1) *
          (this.progress / 100)
      );
      console.log(this.pageCounter);
      if (
        this.pageCounter < 0 ||
        this.pageCounter >= this.content.data.attributes.pages.data.length - 1
      ) {
        this.pageCounter = 0;
      } else {
        this.saveCourseProgress();
      }
    }
  }

  /**
   * Load en check for media in a page which is part of the course.
   */
  private loadMedia(): void {
    if (this.content) {
      this.media = '';
      const currentPage =
        this.content.data.attributes.pages.data[this.pageCounter];

      if (
        currentPage.attributes.media.data !== undefined ||
        currentPage.attributes.youtube_link !== '' ||
        currentPage.attributes.media !== null
      ) {
        if (
          currentPage.attributes.media.data &&
          currentPage.attributes.media.data.attributes.url
        ) {
          const uploaded = currentPage.attributes.media.data;
          this.media = uploaded.attributes.url;
          this.typeMedia = 'uploaded';
        } else if (currentPage.attributes.youtube_link) {
          this.media = currentPage.attributes.youtube_link;
          this.typeMedia = 'youtube';
        }
        if (
          currentPage.attributes.thumbnail &&
          currentPage.attributes.thumbnail.data &&
          currentPage.attributes.thumbnail.data.attributes.url
        ) {
          this.thumbnail = currentPage.attributes.thumbnail.data.attributes.url;
        } else {
          this.thumbnail = ''; // Set a default value if thumbnail is missing
        }
      }
    }
  }

  /**
   * This function is used to finish the course
   */
  public finishCourse(): void {
    // this.bottomSheet.open(FinishCourseDialogComponent, {
    //   data: { courseId: this.id },
    // });
    this.storage.setCourseProgress(this.id + '', 100);
    this.dataService.updateWatchedContent(this.id, 'course');
    this.activityService.postActivity(ActivityType.COURSE);

    this.router.navigate(['/courses']);
  }

  /**
   * This function is used to go to the previous page of a course
   */
  public getPreviousPage(): void {
    if (this.pageCounter > 0) {
      this.pageCounter--;
      this.saveCourseProgress();
    }
  }

  /**
   * This function is used to go to the next page of a course
   */
  public getNextPage(): void {
    if (this.content) {
      if (
        this.pageCounter <
        this.content.data.attributes.pages.data.length - 1
      ) {
        this.pageCounter++;
        this.saveCourseProgress();
      }
    }
  }

  /**
   * Saves the progress of the course to the local storage in the format of key value pair
   */
  private saveCourseProgress(): void {
    if (this.content) {
      let progress =
        ((this.pageCounter + 1) /
          this.content.data.attributes.pages.data.length) *
        100;
      if (progress > 100) progress = 100;
      this.storage.setCourseProgress(this.id + '', progress);
      // console.log(this.id, progress);
      //somehow get this strapi course ID???
      this.loadMedia();
    }
  }
}
