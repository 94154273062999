import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * This service has pure for eventEmitter throughout the application
 *
 * @usage
 * Define your eventEmitter with a clear description.
 */
export class EventService {
  /**
   * Event emitter to trigger the randomChallenge function in the
   * HomeComponent -> tools/pages/challengeComponent
   */
  public triggerRandomChallenge = new BehaviorSubject<boolean>(false);

  // BehaviorSubject to emit the pressed emoji back
  public pressedEmojiEvent = new BehaviorSubject<string>('');

  // BehaviorSubject to reload after a new mental state is saved
  public reloadMentalState = new BehaviorSubject<boolean>(false);

  // relead after a new mental state is saved and send to the api.
  public reloadApiData = new BehaviorSubject<boolean>(false);

  // when the enable or disable state for the algorithm
  public hideRecommendedItem = new BehaviorSubject<boolean>(false);

  // emit boolean true if algorithm is done loading.
  public loadingAlgorithm = new BehaviorSubject<boolean>(true);

  // emit recommended course id to the course page
  public recommendedCourse = new BehaviorSubject<number>(0);

  constructor() {}
}
