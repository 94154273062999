import { Component, OnDestroy, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  faHome,
  faBrain,
  faBars,
  faX,
  faArrowLeft,
  faAddressBook,
  faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';
import { Router, RouterOutlet } from '@angular/router';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { DataService } from 'src/app/core/services/data.service';
import { Preferences } from '@capacitor/preferences';
import packageJSon from '../../../../../package.json';
import { Subscription, map, take } from 'rxjs';
import { NavbarComponent } from '../navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TitleService } from './services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSlideToggleModule,
  _MatSlideToggleRequiredValidatorModule,
} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { EventService } from 'src/app/core/services/event.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { SettingsStorage } from 'src/app/core/interfaces/storage-types';
import { environment } from 'src/environments/environment';
import { WhiteLabelConfig } from 'src/app/core/enums';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  imports: [
    CommonModule,
    NavbarComponent,
    RouterOutlet,
    FontAwesomeModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    FormsModule,
    _MatSlideToggleRequiredValidatorModule,
    MatButtonModule,
  ],
  standalone: true,
})
export class SidenavComponent implements OnInit, OnDestroy {
  public isWhitelabelVariant = false;
  public themeConf = {
    appName: '',
    logoSrc: '',
    logoAlt:'',
  }


  public isChecked = false;
  public isShowing = false;
  public showbackIcon = false;
  public toggle = true;

  public faBars = faBars;
  public faX = faX;
  public faArrowLeft = faArrowLeft;
  public faHome = faHome;
  public faBrain = faBrain;
  public faPrivacy = faShieldHalved;
  public faContact = faAddressBook;

  public appVersion = packageJSon.version;
  public iframeVisible = false;
  public language = '';

  public about = '';
  public privacy = '';
  public locale = '';

  public title?: string;
  private titleSubscription?: Subscription;

  // Notification
  public notificationCheck?: boolean;

  constructor(
    private router: Router,
    private dataService: DataService,
    private titleService: TitleService,
    private translateService: TranslateService,
    private eventService: EventService,
    private storageService: StorageService,
    private notificationService: NotificationService
  ) {
  }

  public translate(key: string) {
    return this.translateService.instant(key);
  }

  // Destroying all subscriptions
  ngOnDestroy(): void {
    this.titleSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    if(environment.variant === WhiteLabelConfig.ARBOLINK){
      this.isWhitelabelVariant = true;
      this.themeConf = {
        appName: 'Arbolink',
        logoSrc: 'assets/Images/logos/arbolink-logo.png',
        logoAlt: 'Arbolink',
      }
    }else{
      this.themeConf = {
        appName: 'Wemind Balance',
        logoSrc: 'assets/Images/logo/wemind-balance-logo.svg',
        logoAlt: 'Wemind Balance',
      }
    }


    this.setCurrentLanguage();
    this.fetchData();
    this.reload();
    this.getTitleSubscription();
    this.isChecked = false;
    this.storageService.setAlgorithmEnable(false);
    this.eventService.hideRecommendedItem.next(false);
    // if (this.storage.getAlgorithmEnable()) {
    //   this.storage.getAlgorithmEnable().subscribe((response) => {
    //     if (response) {
    //       this.isChecked = response;
    //     }
    //   });
    // }

    // get notification enable value
    this.storageService.getSettings().pipe(
      map((settings) => settings[0])
    ).subscribe((settingsResponse:any) => {
      if(settingsResponse){
        this.notificationCheck = settingsResponse.notification
      }else{
        this.notificationCheck = true;
      }
    })
  }

  public toggleAlgorithm(): void {
    this.isChecked = !this.isChecked;
    // this.storage.setAlgorithmEnable(this.isChecked);
    // this.eventService.hideRecommendedItem.next(this.isChecked);
  }

  public toggleNotification():void{
    this.storageService.setSettings({
      notification: !this.notificationCheck
    })

    this.notificationCheck = !this.notificationCheck;

    this.storageService
      .getSettings()
      .pipe(map((settings) => settings[0]))
      .subscribe(async (settingsResponse: SettingsStorage) => {
        if (settingsResponse) {
          if (settingsResponse.notification) {
            await this.notificationService.addNotification();
          } else {
            await this.notificationService.removeAllNotifications();
          }
        } else {
          await this.notificationService.addNotification();
        }
        await this.notificationService.getListOfPendingNotifications();
      });
  }

  /**
   * Get the title subscription from the service.
   */
  private getTitleSubscription(): void {
    this.titleSubscription = this.titleService.title$.subscribe((response) => {
      this.title = response;
    });
  }

  /**
   * Fetch all the data
   */
  private fetchData(): void {
    this.dataService.getSideBar().subscribe({
      next: (response) => {
        this.about = response.data[0].attributes.about;
        this.privacy = response.data[0].attributes.privacyText;
        this.locale = response.data[0].attributes.locale;
      },
      error: (error) => {
        // logging the error if one occures
        console.error(error);
        // redirecting the user to home
        this.router.navigateByUrl('/');
      },
    });
  }

  /**'
   * Listen to the EventEmitter, when this changes
   * Reload the data.
   */
  private reload(): void {
    this.dataService.languageChanged.subscribe(() => {
      this.fetchData();
    });
  }

  public showIframe(): void {
    this.isShowing = false;
    this.iframeVisible = true;
  }

  public hideIframe(): void {
    this.iframeVisible = false;
  }

  public doToggle(): void {
    this.toggle = !this.toggle;
    // Do some other stuff needed
  }

  /**
   * Setting the language from the saved preferences from the user.
   */

  // TODO! PREFERENCES UNTUK SETTING
  async setCurrentLanguage(): Promise<void> {
    const pref = await Preferences.get({ key: 'language' });
    if (pref.value) {
      this.language = pref.value;
      this.translateService.use(pref.value);
    } else {
      this.language = 'nl';
      this.translateService.use('nl');
    }
  }

  /**
   * Switch the icon of the hamburger menu in the top bar
   */
  public switchMenuIcon(): IconDefinition {
    if (!this.isShowing) return this.faBars;
    return this.faX;
  }

  /**
   * Method to change the state of the sidenav, this corresponds to an open or closed sidenav
   */
  public toggleSidenav(): void {
    this.isShowing = !this.isShowing;
  }

  /**
   * Checks if the current component on the routeroutlet is a overview component
   */
  public isOverviewComponent(): void {
    this.showbackIcon = !(
      this.router.url === '/home' ||
      this.router.url === '/courses' ||
      this.router.url === '/tools' ||
      this.router.url === '/balance' ||
      this.router.url === '/about'
    );
  }

  public goBack(): void {
    if (this.router.url.includes('/course-detail')) {
      this.router.navigate(['/course']);
      return;
    }
    if (
      this.router.url.includes('audio-player') ||
      this.router.url.includes('full-video')
    ) {
      this.router.navigate(['/tools']);
      return;
    }
    // If none of the conditions match, navigate to the previous location in the browser's history
    this.router.navigate(['/']);
  }

  /**
   * Changes the style of the title bar element
   */
  public changeTopBarClass(): string {
    if (this.router.url.includes('lockscreen')) {
      return 'exercise-bar';
    } else if (this.router.url.includes('full-screen')) {
      return 'video-margin';
    } else {
      return 'title-bar toolbar';
    }
  }

  /**
   * changes the icons for the different pages.
   * @returns scss class
   */
  public changeSideNavClass(): string {
    if (
      this.router.url.includes('full-video') ||
      this.router.url.includes('audio-player/')
    ) {
      return 'full-screen';
    } else {
      return 'nav-container';
    }
  }

  public changeMarginComponent(): string {
    if (
      this.router.url.includes('workout') ||
      this.router.url.includes('audio-player/') ||
      this.router.url.includes('lockscreen') ||
      this.router.url.includes('full-video/')
    ) {
      return '';
    } else {
      return 'componentStyling';
    }
  }

  /**
   * Function is called when the button changes.
   * @param event value of button that changed
   */
  public onChange(event: MatButtonToggleChange) {
    this.dataService.language = event.value;
    this.translateService.use(event.value);

    Preferences.set({
      key: 'language',
      value: event.value,
    });

    // emit value so the data can be reloaded.
    this.dataService.languageChanged.emit(true);
  }

  public hasRoute(): boolean {
    return (
      this.router.url.includes('workout') ||
      this.router.url.includes('audio-player/challenge') ||
      this.router.url.includes('lockscreen') ||
      this.router.url.includes('full-video/')
    );
  }

  public isLockscreen(): boolean {
    return this.router.url.includes('lockscreen');
  }
}
