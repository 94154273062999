<div class="page-wrapper" *ngIf="length > 0">
  <!-- Progress & content -->
  <div class="progress-bar">
    <app-progressbar
      [numberOfCheckpoints]="length"
      [currentCheckpoint]="pageCounter"
    ></app-progressbar>
  </div>
  <div class="content-container">
    <div
      class="content-box"
      [innerHTML]="
        content!.data!.attributes.pages.data[pageCounter].attributes.content
          | markdown
          | sanitize
      "
    ></div>

    <app-player
      [type]="typeMedia"
      [media]="media"
      [thumbnail]="thumbnail"
    ></app-player>
  </div>
  <!-- Buttons -->
  <div class="navigation-buttons">
    <button
      mat-flat-button
      class="previous-button"
      (click)="getPreviousPage()"
      *ngIf="pageCounter > 0"
    >
      {{ translate("previous") }}
    </button>
    <button
      mat-flat-button
      (click)="getNextPage()"
      *ngIf="pageCounter < length - 1"
    >
      {{ translate("next") }}
    </button>
    <button
      mat-flat-button
      (click)="finishCourse()"
      *ngIf="pageCounter >= length - 1"
    >
      {{ translate("finish") }}
    </button>
  </div>
</div>
