import { WhiteLabelConfig } from 'src/app/core/enums';

export const environment = {
  variant: WhiteLabelConfig.ARBOLINK,
  production: false,
  build: 'dev',
  cmsBaseURL: 'https://dev.psycho.cms.moovd.nl/graphql/',
  authorizationToken:
    'bearer d31711bdb6440cfa16759635516340faf50d712579466bb683b9aa244ce19de446cfe389aa35436834a00d3687bbebdcfa47adaefd09684f9687c2c9d75a45132e30e5b830fcf279404a30c2ea61d037d124266c67c71a81004f8de49e7bbf2448bc28a04d1fba8f4a144a0043c2f8604fb20ef231b688de01e57cdb64c4d01c',
  apiUrl: 'https://dev.psycho.cms.moovd.nl/api',
  testEnvUrl: 'http://localhost:4201/api',
  secretKey: '/A?D(G+KbPeShVmYq3t6w9z$C&E)H@Mc',
  algorithmApiUrl: 'https://api.dev.balance.we-mind.app/api',
  xtoken: 'a10b886c-70c1-4b1d-afda-5411f5f59e13',
};
