import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PincodeService } from '../pincode/pincode.service';
import { ActivityType } from 'src/app/core/enums';
import { DataService } from '../data.service';
import { Observable, from, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private url = `${environment.apiUrl}/user-activities`;
  private bearer = environment.authorizationToken;
  private options = {
    headers: new HttpHeaders({
      Authorization: environment.authorizationToken,
    }),
  };
  private httpOptions = {
    headers: new HttpHeaders({ Authorization: environment.authorizationToken }),
  };

  constructor(
    private http: HttpClient,
    private pincodeService: PincodeService,
    private dataService: DataService
  ) {}

  public getActivitiesByPincode(): Observable<any> {
    return from(this.dataService.checkPreferences()).pipe(
      switchMap(() =>
        this.dataService.buildQuery({
          url: `${environment.apiUrl}/user-activities?populate=*&filters[pincode][pincode][$eq]=${this.pincodeService.getPincode()}`,
          bearer: environment.authorizationToken,
        })
      )
    );
  }

  public postActivity(activityType: ActivityType) {
    const url = `${environment.apiUrl}/user-activities?`;
    const data = {
      data: {
        date: new Date(),
        type: activityType,
      },
    };

    this.http.post(url, data, this.options).subscribe({
      next: (response:any) => {
        console.log('Activity Added!, ', response);
        this.dataService.updatePincode(
          this.pincodeService.getPincode(),
          activityType===ActivityType.LOGIN?true: false,
          undefined,
          undefined,
          undefined,
          response.data.id
        )
      },
      error: (error) => {
        console.error("Failed to add activity!, ", error);
      },
    });
  }
  public addActivity(activityType: ActivityType) {
    const data = {
      data: {
        pincode: {
          connect: [{ id: this.pincodeService.getPincodeId() }],
        },
        date: new Date(),
        type: activityType,
      },
    };

    this.http.post(this.url, data, this.httpOptions).subscribe({
      next: (response) => {
        console.log('next response', response);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
