import { Injectable } from '@angular/core';
import {
  Observable,
  concatMap,
  firstValueFrom,
  from,
  map,
  switchMap,
} from 'rxjs';
import { DataService } from '../data.service';
import { environment } from 'src/environments/environment';
import { ContentService } from '../content/content.service';
import { AuthService } from '../auth/auth.service';
import { UuidService } from '../uuid.service';
import { Preferences } from '@capacitor/preferences';
import { LanguageType, PreferencesKey } from '../../enums';
import { StorageService } from '../storage.service';

interface PincodeGroup {
  pincode: number;
  locale: string;
  groupId: number;
}
@Injectable({
  providedIn: 'root',
})
export class PincodeService {
  private pincode = parseInt(localStorage.getItem('pincode')!);

  constructor(
    private dataService: DataService,
    private contentService: ContentService,
    private authService: AuthService,
    private uuidService: UuidService,
    private storageService: StorageService
  ) {}

  public validatePincode(pincode: number): boolean {
    if (this.authService.newSession(pincode)) {
      localStorage.setItem('pincode', this.pincode.toString());
      localStorage.setItem('userID', this.uuidService.getUserID());
      localStorage.setItem('deviceID', this.uuidService.getDeviceID());
      this.setPincode(pincode);
      return true;
    }

    return false;
  }

  public setUserInfo(pincode: number): Observable<any> {
    return this.getPincodeGroup(pincode).pipe(
      concatMap(async (groupIdNL: number) => {
        await this.storageService.setUserInfo('pincode', pincode);
        localStorage.setItem('pincode', pincode.toString());
        await this.storageService.setUserInfo('groupIdNL', groupIdNL);
        await Preferences.set({
          key: PreferencesKey.LANGUAGE,
          value: LanguageType.EN,
        });

        try {
          const groupIdEN: number = await firstValueFrom(
            this.getPincodeGroup(pincode)
          );
          return groupIdEN;
        } catch (error) {
          return false;
        }
      }),
      concatMap(async (groupIdEN: boolean | number) => {
        await this.storageService.setUserInfo('groupIdEN', groupIdEN);
        await Preferences.set({
          key: PreferencesKey.LANGUAGE,
          value: LanguageType.NL,
        });
        return true;
      })
    );
  }

  private getPincodeGroup(pincode: number): Observable<number> {
    return this.contentService
      .getGroups({
        relationField: [
          {
            attribute: 'pincode_lists',
            fields: ['pincode'],
          },
        ],
      })
      .pipe(
        map((groups: any) => {
          let groupId: number;
          let matchingGroup = groups.data.find((group: any) => {
            let pincode_lists: number[] = [];
            const pincodeList = group.attributes.pincode_lists.data;
            for (let i = 0; i < pincodeList.length; i++) {
              pincode_lists.push(pincodeList[i].attributes.pincode);
            }
            const newPincode = parseInt(pincode.toString()); // keep this
            return pincode_lists.includes(newPincode);
          });
          if (matchingGroup) {
            groupId = matchingGroup.id;
          } else {
            groupId = groups.data[0].id;
          }

          return groupId;
        })
      );
  }

  public getPincode() {
    return this.pincode;
  }

  public setPincode(newPincode: number) {
    this.pincode = newPincode;
  }

  public getPincodeId() {
    let pin = this.pincode;

    from(this.dataService.checkPreferences())
      .pipe(
        switchMap(() =>
          this.dataService.buildQuery({
            url: `${environment.apiUrl}/pincodes?filters[pincode][$eq]=${pin}`,
            bearer: environment.authorizationToken,
          })
        )
      )
      .subscribe({
        next: (r) => {
          console.log(r.data[0].id);
          return r.data[0].id;
        },
      });
  }
}
