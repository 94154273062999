import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private _title$ = new BehaviorSubject<string | undefined>(undefined);

  public title$ = this._title$.asObservable();

  public setTitle(title: string): void {
    this._title$.next(title);
  }

  public getTitle(): string | undefined {
    return this._title$.getValue();
  }
}
