import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { environment } from 'src/environments/environment';

export const isAuthenticated: CanActivateFn = (route, state) => {
  const authenticated = inject(AuthService).validateSession();
  const userPincode = parseInt(localStorage.getItem('pincode')!);
  
  if (!authenticated) {
    inject(Router).navigate(['lockscreen']);
    return false;
  }

  if(!userPincode){
    inject(Router).navigate(['lockscreen']);
    return false;
  }

  return true;
};
