<div class="wrapper">
  <div
    class="circle-wrapper"
    [style]="'--margin-first: ' + ((1 / numberOfCheckpoints) * 100 - 3)"
  >
    <div
      class="circle"
      [ngClass]="{ 'active': i <= currentCheckpoint }"
      *ngFor="let num of [].constructor(numberOfCheckpoints - 1); let i = index"
    >
      <span
        [ngClass]="{ 'active': !(i <= currentCheckpoint) }"
        >{{ i + 1 }}</span
      >
    </div>
    <div class="last-circle">
      <img alt="Medal icon" [src]="themeConf.iconSrc" />
    </div>
  </div>
  <div class="progress-bar" [style]="'--width: ' + calculateProgress()"></div>
</div>
