import { EventEmitter, Injectable } from '@angular/core';
import { Observable, concatMap, from, switchMap, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//change back to environment.ts
import { environment } from 'src/environments/environment';
//
import { Preferences } from '@capacitor/preferences';
import { About } from '../interfaces/collection-types/about.interface';
import { Auth } from '../interfaces/collection-types/auth.interface';
import { Single_challenge } from '../interfaces/collection-types/challenge.interface';
import { Course_detail } from '../interfaces/collection-types/course_detail.interface';
import { Single_course } from '../interfaces/collection-types/courses.interface';
import { Single_mental_workout } from '../interfaces/collection-types/mental_workouts.interface';
import { Sidebar } from '../interfaces/collection-types/sidebar.interface';
import { Theme } from '../interfaces/collection-types/theme.interface';
import { Tool } from '../interfaces/collection-types/tool.interface';
import { PopulateOption } from '../interfaces/data-service/populateOption.interface';
import { QueryBuilderParams } from '../interfaces/data-service/queryBuilderParams.interface';
import { MentalState } from '../interfaces/collection-types/mentalState.interface';
import { StorageService } from './storage.service';
// import { Questionnaire } from '../interfaces/collection-types/questionnaire';

import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = environment.apiUrl;
  private bearer = environment.authorizationToken;
  public language = 'nl';
  public currentTheme = '';
  public pincode = parseInt(localStorage.getItem('pincode')!);
  // EventEmitter to listen to when the language changes.
  public languageChanged = new EventEmitter<boolean>();

  private currentDate;
  private next7days;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.pincode = parseInt(localStorage.getItem('pincode')!);

    this.currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    this.next7days = dayjs().add(7, 'day').format('YYYY-MM-DDTHH:mm:ss');
  }

  /**
   * Check language preferences
   * this function is called before every api call to ensure
   * that the api call has the correct language and theme.
   * */
  async checkPreferences(): Promise<void> {
    const lang = await Preferences.get({ key: 'language' });
    if (lang.value) {
      this.language = lang.value;
    }
    const them = await Preferences.get({ key: 'theme_name' });
    if (them.value) {
      this.currentTheme = them.value;
    }
  }

  /**
   * Builds a query string for a given URL and query options, with optional population options and authorization.
   * @param url The base URL for the query.
   * @param populateOptions The population options, including the fields to populate and any fields to populate on those fields.
   * @param bearer The authorization token, if applicable.
   * @returns An observable of the query result.
   */
  public buildQuery({
    url,
    populateOptions,
    queryOptions,
    bearer,
  }: QueryBuilderParams): Observable<any> {
    let apiUrl = url;

    // Build the population queries, if applicable
    const populateQueries = [];
    if (populateOptions && populateOptions.length > 0) {
      for (let i = 0; i < populateOptions.length; i++) {
        const option = populateOptions[i];
        const fields = Array.isArray(option.fields)
          ? option.fields
          : [option.fields];
        const queries = [];
        for (let j = 0; j < fields.length; j++) {
          queries.push(`populate[${i * (fields.length + 1) + j}]=${fields[j]}`);
        }
        if (option.fieldsToPopulate?.length) {
          for (let j = 0; j < option.fieldsToPopulate.length; j++) {
            queries.push(
              `populate[${i * (fields.length + 1) + fields.length + j}]=${
                option.fieldsToPopulate[j]
              }`
            );
          }
        }
        populateQueries.push(...queries);
      }
    }
    if (populateQueries.length > 0) {
      apiUrl += `?${populateQueries.join('&')}`;
      }

    // get the right theme based on the current selected language
    if (queryOptions?.theme) {
      apiUrl += '&locale=' + this.language + '&filter[title]=Stress';
    }

    // Set up the HTTP options
    const httpOptions = bearer
      ? { headers: new HttpHeaders({ Authorization: bearer }) }
      : {};
    // Send the HTTP request and return the result
    return this.http.get(apiUrl, httpOptions);
  }

  /**
   * Get the themes from strapis
   * @returns A data confom the interface used
   */
  public getThemeDetails(options: PopulateOption): Observable<Theme> {
    const queryOptions = {
      theme: true,
    };
    const populateOptions = [options];
    return from(this.checkPreferences()).pipe(
      switchMap(
        () =>
          this.buildQuery({
            url: `${this.apiUrl}/themes`,
            populateOptions,
            queryOptions,
            bearer: this.bearer,
          }) as Observable<Theme>
      )
    );
  }

  /**
   * Get the content of the about page
   */
  public getAbout(): Observable<About> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/abouts?populate=*&locale=${this.language}`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get the content of the sidebar
   */
  public getSideBar(): Observable<Sidebar> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/sidebars?populate=*&locale=${this.language}`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get the courseDetails and the courses.
   */
  public getCourseDetails(): Observable<Course_detail> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/course-details?filters[theme_name]=${this.currentTheme}&locale=${this.language}&populate[0]=Image`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get a single course based on id
   * @param id of the course
   * @returns data set confrom the interface in the observable
   */
  public getSingleCourse(id: string): Observable<Single_course> {
    const populateOptions = [
      {
        fields: ['pages'],
        fieldsToPopulate: ['pages.media', 'pages.thumbnail'],
      },
    ];

    return this.buildQuery({
      url: `${this.apiUrl}/courses/${id}`,
      populateOptions,
      bearer: this.bearer,
    });
  }

  /**
   * Get the tools and he underlying challenges and mental workouts.
   */
  public getToolsDetails(): Observable<Tool> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/tools?filters[theme_name]=stress&locale=${this.language}&populate[1]=mental_workouts.thumbnail&populate[2]=mental_workouts.audio_file.audio_file&populate[3]=challenges.cover_image`,
          bearer: this.bearer,
        })
      )
    );
  }

  public getToolContents(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/tools?filters[theme_name]=stress&locale=${this.language}&populate=*`,
          bearer: this.bearer,
        })
      )
    );
  }

  public getGroupContents(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/groups?locale=${this.language}&populate[1]=mental_workouts.thumbnail&populate[2]=mental_workouts.audio_file.audio_file&populate[3]=challenges.cover_image&populate[4]=pincode_lists.pincode&populate[5]=courses.pages&populate[6]=courses.thumbnail&populate[7]=courses.icon`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get a single challenge by id
   */
  public getSingleChallenge(id: number): Observable<Single_challenge> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/challenges/${id}?locale=${this.language}&populate[TypeContent][populate]=media&populate[TypeContent][populate]=thumbnail&populate[cover_image]=*&populate[pages][populate]=*`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get a single Audio challenge by id
   */
  public getSingleChallengeAudio(id: number): Observable<Single_challenge> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/challenges/${id}?locale=${this.language}&populate[TypeContent][populate]=*&populate[cover_image]=*&populate[pages][populate]=*`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get single mental workouts by id
   */
  public getSingleMentalWorkouts(
    id: number
  ): Observable<Single_mental_workout> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/mental-workouts/${id}?locale=${this.language}&populate[0]=audio_file.audio_file`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get questions
   */
  public getQuestions(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/questions?locale=${this.language}`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Get questionnaire object
   */
  private getQuestionnaire(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/questionnaires?populate=*&locale=${this.language}`,
          bearer: this.bearer,
        })
      )
    );
  }

  /**
   * Post questionnaire
   */
  public postQuestionnaire(questionnaire: any, formData: Array<object>): void {
    const userPincode = parseInt(localStorage.getItem('pincode')!);
    const url = `${this.apiUrl}/questionnaires?populate=*&locale=${this.language}`;
    const httpOptions = this.bearer
      ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
      : {};

    this.http
      .post(url, questionnaire, httpOptions)
      .pipe(
        concatMap(async (res: any) => {
          const questionnaireId = res.data.id;
          this.postAnswers(formData, questionnaireId);
          return { questionnaireId };
        }),
        concatMap(async ({ questionnaireId }) => {
          if (userPincode != null) {
            this.updatePincode(userPincode, false, questionnaireId);
          }
        })
      )
      .subscribe({
        next: (res) => {
          this.storageService.setQuestionnaireData(userPincode, {
            pincode: userPincode,
            expiredDate: this.next7days,
            formData: formData,
            questionnaire: questionnaire,
          });
          console.log(res);
        },
        error: (error) => {
          this.storageService.setQuestionnaireData(userPincode, {
            pincode: userPincode,
            expiredDate: this.next7days,
            formData: formData,
            questionnaire: questionnaire,
          });
          console.error(error);
        },
      });
  }

  /**
   * Post answers
   */
  private postAnswers(formData: Array<object>, parentId: number): void {
    const url = `${this.apiUrl}/answers?populate=*&locale=${this.language}`;
    const httpOptions = this.bearer
      ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
      : {};

    for (let i = 1; i < Object.keys(formData).length + 1; i++) {
      const answer = {
        data: {
          question: Object.values(formData[i - 1])[0],
          answer: Object.values(formData[i - 1])[1],
          questionType: Object.values(formData[i - 1])[2],
          questionnaire: {
            connect: [
              {
                id: parentId,
              },
            ],
          },
          locale: this.language,
        },
      };
      this.http.post(url, answer, httpOptions).subscribe(
        (response) => {},
        (error) => {
          console.log('Error updating data:', error);
        }
      );
    }
  }

  /**
   * Get the answers linked to this device ID
   */
  private getAnswersIDs(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/answers?populate=*&locale=${this.language}`,
          bearer: this.bearer,
        })
      )
    );
  }

  public getPincode(pin: number): Observable<any> {
    const userPincode = parseInt(localStorage.getItem('pincode')!);
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/pincodes?populate[questionnaires][populate]=answers&populate[mentalstates][populate]=factors&populate[courses]=*&populate[challenges]=*&populate[mental_workouts]=*&[filters][pincode][$eq]=${userPincode}`,
          bearer: this.bearer,
        })
      )
    );
  }

  public getPincodeId() {
    const userPincode = parseInt(localStorage.getItem('pincode')!);
    from(this.checkPreferences())
      .pipe(
        switchMap(() =>
          this.buildQuery({
            url: `${this.apiUrl}/pincodes?filters[pincode][$eq]=${userPincode}`,
            bearer: this.bearer,
          })
        )
      )
      .subscribe({
        next: (r) => {
          console.log(r.data[0].id);
          return r.data[0].id;
        },
      });
  }

  public postPincode(pin: number): void {
    const url = `${this.apiUrl}/pincodes`;

    const httpOptions = this.bearer
      ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
      : {};

    const data = {
      data: {
        pincode: pin,
        lastLogin: new Date(),
        loginCount: 1,
      },
    };

    this.http.post(url, data, httpOptions).subscribe({
      next: (response) => {
        // console.log('result:' + response);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  public updatePincode(
    pin: number,
    fromLogin: boolean,
    questionnaireId?: number,
    mentalStateId?: number,
    contentWatched?: any,
    activityId?: number
  ): void {
    console.log('pincode ' + pin + ' updated! 👌');
    this.getPincode(pin).subscribe({
      next: (r) => {
        if (r.data.length > 0) {
          const url = `${this.apiUrl}/pincodes/${r.data[0].id}`;
          // console.log(r.data);

          const httpOptions = this.bearer
            ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
            : {};

          const data = {
            data: {
              lastLogin: new Date(),
              loginCount: r.data[0].attributes.loginCount,
              questionnaires: {},
              mentalstates: {},
              user_activities: {},
            },
          };

          if (fromLogin) {
            data.data.loginCount = r.data[0].attributes.loginCount + 1;
          }

          if (questionnaireId) {
            data.data.questionnaires = {
              connect: [
                {
                  id: questionnaireId,
                },
              ],
            };
          }

          if (mentalStateId) {
            data.data.mentalstates = {
              connect: [
                {
                  id: mentalStateId,
                },
              ],
            };
          }

          if (activityId) {
            data.data.user_activities = {
              connect: [
                {
                  id: activityId,
                },
              ],
            };
          }

          this.http.put(url, data, httpOptions).subscribe({
            next: (response) => {
              console.log('Pincode data updated!, ', response);
            },
            error: (error) => {
              console.log('pincode data not updated!');
              console.error(error);
            },
          });
        }
      },
    });
  }
  public MentalState(mentalState: MentalState) {
    this.getMentalStatePin().subscribe({
      next: (response) => {
        console.log('response Mental State', response);
        if (response.data.length == 0) {
          this.postMentalState(mentalState);
        } else if (response.data.length > 0) {
          const currentDate = new Date();

          // TODO: fix mental state;
          if (
            response.data[response.data.length - 1].attributes.date ==
            currentDate.toISOString().split('T')[0]
          ) {
            //update instead
            const url = `${this.apiUrl}/mentalstates/${response.data[0].id}`;

            const httpOptions = this.bearer
              ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
              : {};

            const factors = [];

            for (
              let index = 0;
              index < mentalState.chosenButtons.length;
              index++
            ) {
              factors.push({ factor: mentalState.chosenButtons[index] });
            }

            const data: object = {
              data: {
                date: mentalState.date,
                feeling: mentalState.feelingToday,
                factors: factors,
              },
            };

            this.http.put(url, data, httpOptions).subscribe({
              next: (response) => {
                this.getMentalState().subscribe({
                  next: (response) => {
                    this.updatePincode(
                      this.pincode,
                      false,
                      undefined,
                      response.data[response.data.length - 1].id
                    );

                    //add activity
                  },
                  error: (error) => {
                    console.error(error);
                  },
                });
              },
              error: (error) => {
                console.error(error);
              },
            });
          } else {
            this.postMentalState(mentalState);
          }
        }
      },
    });
  }

  public postMentalState(mentalState: MentalState) {
    // Prepare the updated data

    const url = `${this.apiUrl}/mentalstates?`;

    const httpOptions = this.bearer
      ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
      : {};

    const factors = [];

    for (let index = 0; index < mentalState.chosenButtons.length; index++) {
      factors.push({ factor: mentalState.chosenButtons[index] });
    }

    const data: object = {
      data: {
        date: mentalState.date,
        feeling: mentalState.feelingToday,
        factors: factors,
      },
    };

    this.http.post(url, data, httpOptions).subscribe({
      next: (response) => {
        //grabs last created id and assign answers to id
        this.getMentalState().subscribe({
          next: (response) => {
            this.updatePincode(
              this.pincode,
              false,
              undefined,
              response.data[response.data.length - 1].id
            );

            //add activity
          },
          error: (error) => {
            console.error(error);
          },
        });
      },
      error: (error) => {
        console.log('Error updating data:', error);
      },
    });
  }

  private getMentalState(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/mentalstates?populate=*`,
          bearer: this.bearer,
        })
      )
    );
  }

  private getMentalStatePin(): Observable<any> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.buildQuery({
          url: `${this.apiUrl}/mentalstates?populate=*&filters[pincode][pincode][$eq]=${this.pincode}`,
          bearer: this.bearer,
        })
      )
    );
  }

  public updateWatchedContent(id: number, arg1: string) {
    this.getPincode(this.pincode).subscribe({
      next: (r) => {
        if (r.data.length > 0) {
          const url = `${this.apiUrl}/pincodes/${r.data[0].id}`;

          const data = {
            data: {
              courses: {},
              challenges: {},
              mental_workouts: {},
            },
          };
          switch (arg1) {
            case 'course':
              data.data.courses = {
                connect: [
                  {
                    id: id,
                  },
                ],
              };
              break;
            case 'challenge':
              data.data.challenges = {
                connect: [
                  {
                    id: id,
                  },
                ],
              };
              break;
            case 'workout':
              data.data.mental_workouts = {
                connect: [
                  {
                    id: id,
                  },
                ],
              };
              break;
            default:
              break;
          }
          const httpOptions = this.bearer
            ? { headers: new HttpHeaders({ Authorization: this.bearer }) }
            : {};

          this.http.put(url, data, httpOptions).subscribe({
            next: (response) => {
              // console.log(response);
            },
            error: (error) => {
              console.error(error);
            },
          });
        }
      },
    });
  }
}
