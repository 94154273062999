import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, concatMap, from, switchMap } from 'rxjs';
import {
  PopulateOption,
  PopulateParams,
} from '../../interfaces/data-service/populateOption.interface';
import { Populate } from '../../interfaces/strapi-query-builder/query_builder.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getGroups(populate?: Populate): Observable<any> {
    return from(this.preferences()).pipe(
      switchMap(() =>
        this.queryBuilder({
          endpoint: '/groups',
          populate: populate,
        })
      )
    );
  }

  public getGroupsById(id: number, populate?: Populate): Observable<any> {
    return from(this.preferences()).pipe(
      switchMap(() =>
        this.queryBuilder({
          endpoint: `/groups/${id}`,
          populate: populate,
        })
      )
    );
  }

  public getQuotes(populate?: Populate): Observable<any> {
    return from(this.preferences()).pipe(
      switchMap(() =>
        this.queryBuilder({
          endpoint: '/quotes',
          populate: populate,
        })
      )
    );
  }
}
