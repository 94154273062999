import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { WhiteLabelConfig } from 'src/app/core/enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class ProgressbarComponent implements OnInit{
  @Input() numberOfCheckpoints = 7;
  @Input() currentCheckpoint = 0;

  public themeConf =  {
    iconSrc: '',
  };

  ngOnInit(): void {
    if(environment.variant === WhiteLabelConfig.ARBOLINK){
      this.themeConf = {
        iconSrc: 'assets/Icons/arbolink-icon-medal.svg'
      }
    }else{
      this.themeConf = {
        iconSrc: 'assets/Icons/icon-medal.svg'
      }
    }
  }

  faStar = faStar;

  calculateProgress(): number {
    return (
      (this.currentCheckpoint / this.numberOfCheckpoints) * 100 +
      (1 / this.numberOfCheckpoints) * 100
    );
  }
}
