import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UuidService {
  constructor() {}

  private generateUUID(): string {
    return uuidv4();
  }

  // get or create the uuid and save it in localstorage.
  private getOrCreateUUID(key: string): string {
    let id = localStorage.getItem(key);

    if (!id) {
      id = this.generateUUID();
      localStorage.setItem(key, id);
    }

    return id;
  }

  // get UserID
  getUserID(): string {
    return this.getOrCreateUUID('userID');
  }

  // Get DeviceID
  getDeviceID(): string {
    return this.getOrCreateUUID('deviceID');
  }
}
