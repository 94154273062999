import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './core/services/notification/notification.service';
import { StorageService } from './core/services/storage.service';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private storageService: StorageService,
  ) {
    this.translateService.setDefaultLang('nl');
  }


  ngOnInit(): void {



    this.storageService
      .getSettings()
      .pipe(map((settings) => settings[0]))
      .subscribe(async (settingsResponse: any) => {
        if (settingsResponse) {
          if (settingsResponse.notification) {
            await this.notificationService.addNotification();
          } else {
            await this.notificationService.removeAllNotifications();
          }
        } else {
          await this.notificationService.addNotification();
        }
        await this.notificationService.getListOfPendingNotifications();
      });
  }
}
