import { Component, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faHome,
  faCube,
  faChartLine,
  faBook,
  faScaleUnbalanced,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [FontAwesomeModule, MatToolbarModule, RouterModule],
  standalone: true,
})
export class NavbarComponent implements OnInit {
  public faHome = faHome;
  public faCube = faCube;
  public faChartLine = faChartLine;
  public faBook = faBook;
  public fabalance = faScaleUnbalanced;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  translate(key: string) {
    return this.translateService.instant(key);
  }

  public resetTabIndex(): void {
    sessionStorage.setItem('tabIndex', `${0}`);
  }
}
